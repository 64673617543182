.Wrapper {
  margin-bottom: $margin24;
}

.ShortTitle {
  display: block;
  margin-bottom: $margin4;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 13px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 14px;
  }
}

.Title {
  margin-bottom: 5px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 11px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 15px;
  }
}

/* ---- fonts for theme default ---- */

.ShortTitleFont {
  composes: HeadingCatch3 from 'typography.legacy.css';
}

.TitleFont {
  composes: Heading1A from 'typography.legacy.css';
}

.Lead {
  composes: Lead1 from 'typography.legacy.css';
}

/* ---- theme for video detail page ---- */

.WrapperSmall {
  margin-bottom: 19px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin32;
  }
}

.ShortTitleFontSmall {
  composes: HeadingCatch2 from 'typography.legacy.css';
}

.TitleFontSmall {
  composes: Heading3A from 'typography.legacy.css';

  @media (min-width: $smBreakpoint) {
    margin-bottom: 15px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 20px;
  }
}

.LeadFontSmall {
  composes: Paragraph from 'typography.legacy.css';
}
