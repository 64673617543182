.AuthorWrapper {
  @mixin Paragraph;
  margin-bottom: 30px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}

.ShortTitleWrapper.SubscriptionShortTitle {
  align-items: center;
  flex-direction: row;

  @media (min-width: $smBreakpoint) {
    align-items: flex-start;
  }
}

.ShortTitleWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    flex-direction: row;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin8;
  }
}

.ShortTitle, .BadgeWrapper {
  composes: HeadingCatch3 from 'typography.legacy.css';
  

  @media (min-width: $smBreakpoint) {
    margin-top: 0;
  }
}

.ArticleDefaultWrapper {
  overflow: hidden;
}

.ArticleAlertsWrapper {
  margin: $margin32 0 $margin40;

  @media (min-width: $smBreakpoint) {
    margin: $margin40 0 $margin56;
  }
}

.UtilityBarWrapper {
  @mixin hideforPrint;
  background-color: $white;
  border-top: 1px solid $grayA;
  bottom: 0;
  height: 65px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: calc($zIndexHeaderAd - 1);
  
  @media (min-width: $smBreakpoint) {
    background-color: $transparent;
    border-top: 0;
    height: auto;
    margin-bottom: $margin32;
    margin-top: $margin8;
    position: relative;
    z-index: $zIndex50;
  }
}

.ArticleBody {
  :global(#app) {
    padding-bottom: 65px;

    @media (min-width: $smBreakpoint) {
      padding-bottom: 0;
    }
  }
}

$paywallHeightXs: 882px;
$paywallHeightSm: 692px;

.Paywall {
  background-color: $white;
  bottom: 0;
  margin-bottom: 80px;
  opacity: 1;
  position: relative;
  transition: opacity 0.3s;
  width: 100%;

  &::before {
    background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0.02%, $white 100%);
    content: '';
    display: inline-block;
    height: 200px;
    left: 0;
    position: absolute;
    right: 0;
    top: -200px;
  }

  :global(#piano-inlined) {
    position: relative;
    z-index: 1;
  }
}

.HiddenPaywall {
  opacity: 0;
  padding-bottom: 0;
}

.SubscriptionBadgeWrapper{
  margin-right: $margin8;
}

/* show hint that the image might not have been downloaded */
:global(.article-detail .default-image-paragraph .print-info) {
  @media print { 
    display: flex;
    width: 25%;
  }
}

.PodcastProfileBoxWrapper {
  composes: Container from '@grid.legacy.css';

  @media (max-width: $xsBreakpointTo) {
    margin-left: auto;
    margin-right: auto;
    padding-left: calc($gridGutterWidthXs / 2);
    padding-right: calc($gridGutterWidthXs / 2);
    width: 100%;
  }
}


@keyframes pulsing {
  50% {
    opacity: 0.5;
  }
}

:global(.PaywallSkeleton) {
  animation: pulsing 1.5s infinite;
  background-color: $grayPlaceholder;
  border-top: 4px solid $redA;
  left: calc($gridGutterWidthXs / 2);
  min-height: $paywallHeightXs;
  position: absolute;
  right: calc($gridGutterWidthXs / 2);
  top: 0;
  z-index: 0;

  @media (min-width: $smBreakpoint) {
    left: calc($gridGutterWidthMd / 2);
    min-height: $paywallHeightSm;
    right: calc($gridGutterWidthMd / 2);
  }
}

