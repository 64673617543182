.Wrapper {
  margin-bottom: 23px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 35px;
  }

  &.InfoBoxMargin {
    @media (min-width: $xlBreakpoint) {
      margin-bottom: 21px;
    }
  }
}

.ImageInnerWrapper {
  display: inline-block;
  position: relative;

  @media print {
    width: 100%;
  }
}

.TitleWrapper {
  composes: ColOffsetXs2 from '@grid.legacy.css';
  composes: ColXs20 from '@grid.legacy.css';
  composes: ColOffsetSm4 from '@grid.legacy.css';
  composes: ColSm16 from '@grid.legacy.css';
  composes: ColOffsetXl5 from '@grid.legacy.css';
  composes: ColXl14 from '@grid.legacy.css';
  composes: TextCenter from 'helpers.legacy.css';
  composes: TitleWrapper from 'Paragraphs/components/VideoParagraph/styles.legacy.css';
}

.Title {
  composes: Quote from 'Paragraphs/components/VideoParagraph/styles.legacy.css';
}
