.Reply {
  composes: CaptionTitleAuthorParagraph from 'typography.legacy.css';
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: right;
  width: 100%;
}

.Icon {
  font-size: 2rem;
  margin-bottom: -5px;
  margin-left: 5px;
  padding-left: 6px;
}

.IconChevronUp,
.IconChevronUpActive {
  position: relative;
  top: 5px;
  transition: transform 300ms linear;
}

.IconChevronUpActive {
  transform: rotate(180deg);
}
