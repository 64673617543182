.Wrapper {
  composes: Container from '@grid.legacy.css';
  padding: 16px 0;

  @media (min-width: $smBreakpoint) {
    padding: 32px 0;
  }
}

.Title {
  composes: Heading5 from 'typography.legacy.css';
  margin-bottom: $margin16;
}

.Content {
  composes: RichtextWrapper from '../TextParagraph/styles.legacy.css';
  @mixin Paragraph; /* mixin because RichTextWrapper has different font-family */
  list-style-position: inside;
  margin-bottom: $margin16;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin24;
  }
}

.ImageBox {
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.Footer {
  composes: RichtextWrapper from '../TextParagraph/styles.legacy.css';
  @mixin ParagraphBold; /* mixin because RichTextWrapper has different font-family */

  p {
    @mixin ParagraphBold; /* mixin because RichTextWrapper has different font-family */
  }
}

.Even {
  background: $gradientSkin;
}

.Odd {
  .ImageBox {
    order: -1;
  
    @media (min-width: $smBreakpoint) {
      order: initial;
    }
  }
}

.ListicleItemWrapper {
  composes: Row from '@grid.legacy.css';
}

.ListicleItemInnerWrapper {
  composes: ListicleItemWrapper;
}

.Image {
  max-width: 100%;
}
