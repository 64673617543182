.Wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin16;
  position: relative;
  text-align: start;
}

.FieldWithHelperTextWrapper {
  margin-bottom: $margin12;
}

.Input,
.Textarea {
  composes: FormLabelBigBlack from 'typography.legacy.css';
  background-color: $grayF;
  border: none;
  border-bottom: 1px solid $grayA;
  border-radius: 4px 4px 0 0;
  height: 54px;
  padding: 24px 12px 6px;
  transition: border-color 300ms ease-in-out, box-shadow 300ms ease-in-out, background-color 300ms ease-in-out;
  width: 100%;

  &[type='tel'] {
    background-color: $grayF; /* needed to override styling from _reset.legacy.css */
  }

  &:not(.Disabled):hover {
    background-color: $grayE;
    border-color: $black;
  }

  &:not(.Disabled):focus,
  &:not(.Disabled):active {
    background-color: $grayE;
    border-color: $black;
    box-shadow: inset 0 -1px 0 $black;
    outline: none;

    & + label {
      animation: none;
      color: $black;
    }
  }

  &.HasError {
    border-color: $redA;

    &:focus,
    &:active {
      border-color: $redA;
      box-shadow: inset 0 -1px 0 $redA;
    }
  }
}

.Textarea {
  box-shadow: none;
  padding: 24px 12px 12px;
  resize: none;
}

.HelperText {
  composes: FormLabelSmallBlack from 'typography.legacy.css';
  display: block;
  margin-top: $margin4;
}

.ErrorMessage {
  composes: FormLabelSmallBlack from 'typography.legacy.css';
  animation: ErrorMessageEnterAnimation 0.1s;
  color: $redA;
  display: block;
  margin-top: $margin4;

  &.ReplacedHelperText {
    animation: none;
  }
}

.ErrorIcon {
  color: $redA;
  font-size: 18px;
  margin-top: $margin16;
  position: absolute;
  right: 12px;
}

.OptionWrapper {
  align-items: center;
  display: flex;
  padding-top: 16px;
}

.Option {
  height: 24px;
  position: relative;

  & > span {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
      position: absolute;
    }
  }

  & > input[type='checkbox'] {
    appearance: none;
    background-color: $white;
    border: 1px solid $grayC;
    border-radius: 2px;
    cursor: pointer;
    height: 24px;
    margin: 0;
    outline: 0;
    padding: 16px 0 0;
    position: relative;
    width: 24px;

    &:not(.Disabled):hover {
      background-color: $transparent;
      border-color: $black;
      box-shadow: none;
    }

    &:checked {
      background-color: $white;
      border-color: $black;
    }

    &:checked + span {
      overflow: hidden;

      &::after {
        @mixin FontIcons;
        background-color: $black;
        border-radius: 1px;
        content: '';
        height: 24px;
        left: 0;
        top: 0;
        width: 24px;
      }
    }

    &.Disabled {
      border-color: $grayB; 
      cursor: not-allowed;
    }

    @media (min-width: $smBreakpoint) {
      &:checked::after {
        top: -6px;
      }
    }
  }

  & > input[type='radio'] {
    appearance: none;
    background: $white;
    border: 1px solid $grayC;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    margin: 0;
    outline: 0;
    padding: 16px 0 0;
    position: relative;
    width: 24px;

    &:not(.Disabled):hover {
      border-color: $black;
    }

    &:checked + span::after {
      background-color: $black;
      border-radius: 50%;
      content: '';
      height: 16px;
      left: 4px;
      top: 4px;
      width: 16px;
    }

    &.Disabled {
      border-color: $grayB; 
      cursor: not-allowed;
    }
  }
}

.Labels {
  composes: FormLabelBigGray from 'typography.legacy.css';
  cursor: pointer;
  margin-left: $margin12;
  padding: 8px 0;
  transition: color 300ms ease-in-out;


  &:hover,
  &:hover a {
    color: $black;
  }

  & a {
    border-bottom: 1px solid $grayB;
    color: $grayB;
    transition: color 300ms ease-in-out;
  }

  .Required {
    &::after {
      content: '*';
      display: inline;
    }
  }
}

.Description {
  composes: FormLabelBigBlack from 'typography.legacy.css';
  margin-bottom: $margin12;
  margin-left: $margin12;
  transition: color 300ms ease-in-out;

  &:hover,
  &:hover a {
    color: $black;
  }

  & a {
    border-bottom: 1px solid $grayB;
    color: $grayB;
    transition: color 300ms ease-in-out;
  }
}

.Disabled {
  color: $grayB !important; /* stylelint-disable-line */ /* needed to override styling from not common styles */;
  cursor: not-allowed;
  outline: none;

  &:hover {
    color: $grayB !important; /* stylelint-disable-line */ /* needed to override styling from not common styles */;
  }
}

@mixin ErrorMessageEnterAnimation;
