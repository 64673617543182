.GridRow {
  margin-bottom: 30px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}

.Divider {
  background-color: $grayA;
  height: 1px;
  position: relative;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin: 0 auto;
    width: 252px;
  }

  @media (min-width: $xlBreakpoint) {
    width: 300px;
  }
}
