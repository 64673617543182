.Link {
  composes: InfoParagraphBlue from 'typography.legacy.css';
  color: $black30;
  cursor: pointer;
  display: inline-block;
  font-family: $fontFamilyMontserrat;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2.4rem;
  margin: 16px 0 0;
  text-decoration: underline;
  
}

.Title {
  composes: Heading1B from 'typography.legacy.css';
  margin-bottom: 15px;
}

.Wrapper {
  composes: Clearfix from 'helpers.legacy.css';
  background-color: $grayE;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 100%;
}

.Lead {
  composes: Lead1 from 'typography.legacy.css';
  margin-bottom: 21px;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 100%;
  padding: 30px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    padding: 26px;
  }

  @media (min-width: $mdBreakpoint) {
    padding: 28px;
  }
}

.LinkWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
