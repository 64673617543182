.Item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 30px;

  @media (min-width: $smBreakpoint) {
    flex-wrap: wrap;
    margin-bottom: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}

.ImageWrapper {
  display: flex;
  margin-right: 20px;
  max-height: 120px;
  width: 100px;

  @media (min-width: $smBreakpoint) {
    height: 170px;
    margin: 0 0 15px;
    width: 170px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 194px;
    margin-bottom: 17px;
    width: 194px;
  }
}

.Image {
  align-self: flex-end;
  flex-shrink: 0;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;

  @media (min-width: $smBreakpoint) {
    align-self: flex-end;
  }
}

.ContentWrapper {
  align-self: flex-end;
  min-width: 0;
  width: calc(100% - 100px - 20px);
  word-wrap: break-word;

  @media (min-width: $smBreakpoint) {
    text-align: center;
    width: 100%;
  }
}

.ShortTitle {
  margin-bottom: $margin4;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 6px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin8;
  }
}

.Currency {
  font-variant-caps: all-small-caps;
}

.Button {
  margin-top: 15px;

  @media (min-width: $smBreakpoint) {
    margin-top: 19px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-top: $margin16;
  }
}
