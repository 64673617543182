.Icon {
  composes: FontIcons from 'typography.legacy.css';

  display: inline-block;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $fontFamilyIcons !important; /* stylelint-disable-line */
  font-size: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.IconInstagram::before {
  content: '\e900';
}

.IconCamera::before {
  content: '\e911';
}

.IconFotoMarker::before {
  content: '\e912';
}

.IconArrowLeftButton::before {
  content: '\e913';
}

.IconArrowLeftButtonOutline::before {
  content: '\e914';
}

.IconArrowRightButton::before {
  content: '\e915';
}

.IconArrowRightButtonOutline::before {
  content: '\e916';
}

.IconCloseButtonSimple::before {
  content: '\e917';
}

.IconCloseButton::before {
  content: '\e918';
}

.IconCloseButtonOutline::before {
  content: '\e919';
}

.IconMovieButton::before {
  content: '\e91a';
}

.IconMovieOutline::before {
  content: '\e91b';
}

.IconInfoButtonOutline::before {
  content: '\e91c';
}

.IconFullscreenButton::before {
  content: '\e91d';
  
  @media print {
    display: none;
  }
}

.IconInfoButton::before {
  content: '\e91e';
}

.IconTwitter::before {
  content: '\e901';
}

.IconPinterest::before {
  content: '\e902';
}

.IconFacebook::before {
  content: '\e904';
}

.IconYoutube::before {
  content: '\e94c';
}

.IconTickmark::before {
  content: '\e90c';
}

.IconEnvelope::before {
  content: '\e920';
}

.IconArrowLeft::before {
  content: '\e922';
}

.IconArrowRight::before {
  content: '\e923';
}

.IconCollapse::before {
  content: '\e924';
}

.IconExpand::before {
  content: '\e925';
}

.IconChevronUp {
  composes: IconCollapse;
}

.IconBell::before {
  content: '\e92a';
}

.IconBookmark::before {
  content: '\e949';
}

.IconWarning::before {
  content: '\e92f';
}

.IconCircleCheckSolid::before {
  content: '\e92e';
}

.IconComments::before {
  content: '\e926';
}

.IconGear::before {
  content: '\e941';
}

.IconNewspaper::before {
  content: '\e93f';
}

.IconArrowUpFromSquare::before {
  content: '\e942';
}

.IconLogout {
  composes: IconArrowUpFromSquare;
  transform: rotate(90deg);
}

.IconXMark {
  composes: IconCloseButtonSimple;
}

.IconCircleInfo::before {
  content: '\e943';
}

.IconCircleCheck::before {
  content: '\e944';
}

.IconCircleExclamation::before {
  content: '\e945';
}

.IconTriangleExclamation::before {
  content: '\e946';
}

.IconDocument::before {
  content: '\e94e';
}

.IconMagnifyingGlass::before {
  content: '\e921';
}

.IconServices::before {
  content: '\e954';
}

.IconPlusThin::before {
  content: '\e925';
}

.IconMinusThin::before {
  content: '\e924';
}

.IconChevronDown {
  composes: IconExpand;
}
