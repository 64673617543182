.Inner {
  padding: 2px 0 24px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 34px;
    padding-top: 14px;
  }
}

.Title {
  @mixin ParagraphBold;
  align-items: center;
  background: none;
  border: none;
  color: $black;
  cursor: pointer;
  justify-content: space-between;
  margin-top: 27px;
  outline: none;
  padding: 8px 0;
  position: relative;
  width: 100%;
}

.Counter {
  color: $white;
  display: inline-block;
  text-align: center;
  width: 32px;

  &::before {
    @mixin FontIcons;
    color: $black;
    content: '\e926';
    display: inline-block;
    font-size: 32px;
    left: 0;
    position: absolute;
    top: 8px;
    z-index: -1;
  }
}

.Pager {
  align-items: center;
  background: none;
  border-color: $grayA;
  border-style: solid;
  border-width: 0 0 2px;
  color: $grayA;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 20px 0;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    padding: 26px 0;
  }
}

.Icon {
  font-size: 15px;
  padding-left: 7px;
}
