.InnerWrapper {
  align-items: center;
  background: $grayA25;
  display: flex;
  flex-direction: column;
  margin-bottom: $margin24;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.Text {
  composes: Paragraph from 'typography.legacy.css';
  margin: 0 $margin16 $margin24 $margin16;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    max-width: 583px;
  }

  @media (min-width: $xlBreakpoint) {
    max-width: 1165px;
  }
}

.Icon {
  animation: BookmarkAnimation 500ms forwards;
  animation-delay: 500ms;
  color: $black;
  font-size: 8rem;
  line-height: 8rem;
  margin: $margin24 auto $margin8 auto;
  transform-origin: 50% 25%;

  @media (min-width: $smBreakpoint) {
    margin: $margin48 auto $margin24 auto;
  }
}

.Wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: $margin56;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    background: $grayA25;
    margin-bottom: $margin40;
    padding-bottom: 48px;
  }
}

.Link {
  display: block;
  height: auto;
  text-align: center;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    width: auto;
  }
}

@mixin BookmarkAnimation;
