.Message {
  composes: Message from 'LoginForm/styles.legacy.css';
}

.ButtonWrapper {
  text-align: center;
}

.Button {
  composes: Button from 'LoginForm/styles.legacy.css';
}
