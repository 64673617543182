.Navigation {
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-left: $margin16;
    width: auto;
  }

  @media (min-width: $mdBreakpoint) {
    margin-left: 27px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-left: $margin40;
  }
}

.List {
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (min-width: $smBreakpoint) {
    justify-content: flex-start;
  }
}

.ListItem {
  composes: HeaderMenuItems from 'typography.legacy.css';
  list-style: none;
  margin-right: 13px;
  padding: 9px 0;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $smBreakpoint) {
    margin-right: 18px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-right: 27px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-right: 45px;
  }

  &.Active .Link {
    @mixin FontMontserratBold;
  }
}

.Link {
  color: $black;
  display: block;
  position: relative;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    line-height: 2.2rem;

    &::after {
      @mixin gradientSiDefault;
      bottom: -3px;
      content: '';
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
    }

    &:focus {
      &::after {
        opacity: 0;
      }
    }

    &.Active {
      @mixin FontMontserratBold;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2.5rem;
    line-height: 2.5rem;

    &::after {
      bottom: -6px;
      height: 2px;
    }
  }
}
