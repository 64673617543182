.Wrapper {
  margin-bottom: 20px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}

.HeadingCatch3 {
  composes: HeadingCatch3 from 'typography.legacy.css';
  display: block;
  margin-bottom: $margin4;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 13px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 14px;
  }
}

.ShortTitleWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    flex-direction: row;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin8;
  }

  .HeadingCatch3 {
    margin-bottom: 0;
    margin-top: 10px;

    @media (min-width: $smBreakpoint) {
      margin-left: 20px;
      margin-top: 0;
    }

    @media (min-width: $xlBreakpoint) {
      margin-left: 25px;
    }
  }
}

.Heading1A {
  composes: Heading1A from 'typography.legacy.css';
  margin-bottom: 5px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 11px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 15px;
  }
}

.Lead1 {
  composes: Lead1 from 'typography.legacy.css';
}
