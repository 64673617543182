.Wrapper {
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
}

.ContactInput,
.Textarea {
  @mixin Paragraph;
  border: 1px solid $grayA;
  border-radius: 0;
  margin-top: 10px;
  padding: 15px 0 0;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.Textarea {
  height: 30px;
  margin-bottom: -5px;
  margin-top: 25px;
  padding-top: 0;
  resize: none;

  @media (min-width: $smBreakpoint) {
    margin-top: 25px;
  }
}

.Label {
  @mixin Paragraph;
  color: $grayB;
  height: 30px;
  margin: $margin24 0 0;
  pointer-events: none;
  position: absolute;
  transform-origin: left;
}

.TextareaLabel {
  margin: 30px 0 0;

  @media (min-width: $smBreakpoint) {
    margin: 26px 0 0;
  }
}

.LabelAbove {
  transform: scale(0.7) translateY(-106%);
  transition: transform 0.8s;
}

.LabelInside {
  transform: scale(1) translate(0, 0);
  transition: transform 0.8s;
}

.HasError {
  border-color: $redA;
  color: $redA;
}

.ErrorMessage {
  display: none;
}

@media (min-width: $smBreakpoint) {
  /* 760 */
  .Label,
  .TextareaLabel,
  .Textarea,
  .ContactInput {
    font-size: 2rem;
  }
}
