.Wrapper {
  overflow: hidden;
  @mixin hideforPrint;
  transition: height 0.3s; /* change transition timeout in index.js as well! */

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin24;
  }
}

.InnerWrapper {
  composes: Container from '@grid.legacy.css';
}

:global(.branding-day) {
  .InnerWrapper {
    margin: 0 auto;
    width: 100%;
  }
}
