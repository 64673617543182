.Wrapper {
  composes: FontTimes from 'typography.legacy.css';
  background: $grayA;
  font-size: 1.4rem;
}

.WrapperInner {
  composes: Container from '@grid.legacy.css';
}

.ListWrapper {
  display: block;
  text-align: right;
}

.ListItem,
.CloseButtonWrapper {
  color: $black;
  display: inline-block;
  list-style: none;

  & + .ListItem:not(:last-of-type)::before {
    content: '|';
  }
}

.CloseButtonWrapper {
  vertical-align: middle;

  .CloseButton {
    appearance: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
      color: $redC;
    }
  }
}

.Link {
  color: $black;
  display: inline-block;
  padding: 10px 5px;

  &:hover {
    text-decoration: underline;
  }
}

.Icon {
  &::before {
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 600;
  }
}
