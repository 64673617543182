.AuthorDetails {
  padding-bottom: 24px;

  @media (min-width: $smBreakpoint) {
    align-items: flex-start;
    display: flex;
    padding-bottom: 40px;
  }
}

.PictureWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Initials,
.Picture {
  background: $greyB;
  border-radius: 50%;
  height: 70px;
  width: 70px;

  @media (min-width: $smBreakpoint) {
    height: 100px;
    width: 100px;
  }

  @media (min-width: $mdBreakpoint) {
    height: 115px;
    width: 115px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 140px;
    width: 140px;
  }
}

.Initials {
  align-items: center;
  background: $greyB;
  color: $blackC;
  display: flex;
  font-family: $fontFamilyGeorgia;
  font-size: 2.7rem;
  font-weight: 700;
  justify-content: center;
  line-height: 2.9rem;

  @media (min-width: $smBreakpoint) {
    font-size: 3.9rem;
    line-height: 2.9rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
    line-height: 2.9rem;
  }
}

.DetailsContent {
  @media (min-width: $smBreakpoint) {
    margin-left: 14px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-left: 21px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-left: 24px;
  }
}

.Name {
  composes: TeaserTitleH1 from 'typography.legacy.css';
  align-items: center;
  display: flex;
  margin-bottom: $margin8;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.SubscribeButton {
  margin-left: $margin24;
}

.Headline {
  composes: Lead from 'typography.legacy.css';

  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin24;
  }

  @media (min-width: $xlBreakpoint) {
    text-align: left !important; /* stylelint-disable-line */ /* to override compose Lead */
  }
}

.ContactsList {
  @media (min-width: $smBreakpoint) {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.ContactItem {
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }

  &.Dropdown {
    margin-bottom: $margin48;
    margin-inline: $outerGapXs;

    .ContactLink {
      color: $blackA;

      .ContactIcon {
        fill: $blackA;

        > svg {
          height: 20px;
          max-height: 20px;
          max-width: 20px;
          width: 20px;
        }

        path {
          fill: $blackA;
        }
      }
      
      .IconWrapper {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.IconWrapper {
  height: 14px;
  margin-right: 8px;
  width: 14px;
}

.ContactIcon {
  fill: $greyA;
  height: 14px;
  width: 14px;
  
  > svg {
    max-height: 14px;
    max-width: 14px;
  }
}

.ContactLink {
  composes: Meta3Black from 'typography.legacy.css';
  align-items: center;
  color: $greyA;
  display: flex;

  &:hover {
    color: $blackA;

    .ContactIcon {
      fill: $blackA;

      path {
        fill: $blackA;
      }
    }
  }
}
