.Wrapper {
  background-color: $black;
  height: 100%;
  inset: 0;
  opacity: 0;
  position: fixed;
  transition: z-index 0.3s;
  z-index: -1;
}

.FadeIn {
  animation: fade-in 0.3s forwards;
  z-index: $zIndexOverlay;
}

.BodyClass {
  overflow: hidden;
  position: relative;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}
