.Wrapper {
  display: inline-block;
  line-height: 0;
  margin-bottom: 15px;
  margin-left: -$outerGapXs;
  margin-right: -$outerGapXs;
  position: relative;
  width: calc(100% + $outerGapXs * 2);

  @media (min-width: $smBreakpoint) {
    margin-bottom: 38px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 52px;
  }

  &::before {
    background: $gradientTeaser;
    bottom: 0;
    content: '';
    height: 140px;
    left: 0;
    position: absolute;
    right: 0;

    @media (min-width: $smBreakpoint) {
      height: 265px;
    }

    @media (min-width: $xlBreakpoint) {
      height: 424px;
    }
  }

  &:hover {
    .Title .TitleInner {
      box-shadow: $shadowH;
    }
  }
}

.ContentWrapper {
  bottom: -21px;
  left: $outerGapXs;
  max-width: 420px;
  padding-right: 15px;
  position: absolute;
  word-wrap: break-word;
  z-index: calc($zIndex1 + 1);

  @media (min-width: $smBreakpoint) {
    bottom: -38px;
    left: 20px;
    max-width: 573px;
    padding-right: 0;
  }

  @media (min-width: $xlBreakpoint) {
    left: 30px;
    max-width: 702px;
  }
}

.Title {
  composes: Heading1B from 'typography.legacy.css';

  > .TitleInner {
    background-color: $white;
    box-decoration-break: clone;
    box-shadow: $shadowG;
    padding: 0 6px 1px;
    transition: box-shadow 0.3s;

    @media (min-width: $smBreakpoint) {
      padding: 0 10px;
    }

    @media (min-width: $xlBreakpoint) {
      line-height: 7rem;
      padding: 1px 10px 2px;
    }
  }
}

.ShortTitle {
  composes: HeadingCatch1 from 'typography.legacy.css';
  padding: 5px 6px;

  @media (min-width: $smBreakpoint) {
    padding: 0 10px 6px;
  }
}

.Badge {
  margin-bottom: 5px;
  padding-left: 6px;
  white-space: nowrap;

  @media (min-width: $smBreakpoint) {
    padding-left: 10px;
  }
}

.IconStyle {
  color: $white;
  display: inline-block;
  font-size: 1.2rem;
  left: 6px;
  position: relative;
  text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  top: -5px;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    left: 10px;
    top: -10px;
  }
  @media (min-width: $xlBreakpoint) {
    font-size: 2.5rem;
  }
}

.IconMovieOutlineStyle {
  composes: IconMovieOutlineStyle from 'App/components/Teaser/styles.legacy.css';
  font-size: 5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 6rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 8rem;
  }
}
