.Wrapper {
  margin-bottom: 19px;
  margin-left: -$outerGapXs;
  margin-right: -$outerGapXs;
  position: relative;
  width: calc(100% + $outerGapXs * 2);

  @media (min-width: $smBreakpoint) {
    margin: 0;
    margin-bottom: 26px;
    width: 100%;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 46px;
  }

  &::before {
    background: $gradientTeaser;
    bottom: 3px;
    content: '';
    height: 140px;
    left: 0;
    position: absolute;
    right: 0;

    @media (min-width: $smBreakpoint) {
      height: 300px;
    }

    @media (min-width: $lgBreakpoint) {
      height: 355px;
    }
  }
}

.Image {
  height: auto;
  width: 100%;
}

.ContentWrapper {
  bottom: -18px;
  left: $outerGapXs;
  max-width: 350px;
  padding-right: 15px;
  position: absolute;
  z-index: calc($zIndex1 + 1);

  @media (min-width: $smBreakpoint) {
    bottom: -16px;
    left: 0;
    max-width: 300px;
    min-width: 415px;
    padding-right: 0;
  }

  @media (min-width: $mdBreakpoint) {
    bottom: -32px;
  }

  @media (min-width: $xlBreakpoint) {
    bottom: -40px;
    max-width: 670px;
  }
}

.Title {
  composes: Heading1B from 'typography.legacy.css';
  display: inline;
  line-height: 1.4;

  > .TitleInner {
    background-color: $white;
    box-decoration-break: clone;
    padding: 0 6px 2px;
    @media (min-width: $smBreakpoint) {
      padding: 0 10px 2px;
    }
  }
}

.ShortTitle {
  composes: HeadingCatch1 from 'typography.legacy.css';
  max-width: 250px;
  padding: 5px 6px;

  @media (min-width: $smBreakpoint) {
    max-width: 225px;
    padding: 5px 10px;
  }

  @media (min-width: $mdBreakpoint) {
    max-width: 270px;
  }
  
  @media (min-width: $xlBreakpoint) {
    max-width: 100%;
  }
}

.ImageCredit {
  composes: CaptionAuthorCopyRight from 'typography.legacy.css';
  bottom: 100%;
  color: $white;
  line-height: initial;
  margin-bottom: 5px;
  position: absolute;
  right:0;
  text-shadow: 1px 1px $grayB;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  width: 56%;
  

  @media (min-width: $smBreakpoint) {
    bottom: -1rem;
    color: $grayB;
    line-height: 8px;
    margin-bottom: initial;
    right: 0;
    text-shadow: initial;
    transform: rotate(0deg);
    width: initial;
  }
}
