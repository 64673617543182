@define-mixin FontTimes {
  font-family: $fontFamilyTimes;
  font-style: normal;
  font-weight: normal;
}

@define-mixin FontTimesBold {
  @mixin FontTimes;
  font-weight: bold;
}

@define-mixin FontGeorgia {
  font-family: $fontFamilyGeorgia;
  font-style: normal;
  font-weight: normal;
}

@define-mixin FontGeorgiaBold {
  @mixin FontGeorgia;
  font-weight: bold;
}

@define-mixin FontMontserrat {
  font-family: $fontFamilyMontserrat;
  font-style: normal;
  font-weight: 300;
}

@define-mixin FontMontserratBold {
  @mixin FontMontserrat;
  font-weight: 700;
}

@define-mixin FontMontserratItalic {
  @mixin FontMontserrat;
  font-style: italic;
}

@define-mixin FontMontserratBoldItalic {
  @mixin FontMontserrat;
  font-style: italic;
  font-weight: 700;
}

@define-mixin FontIcons {
  font-family: $fontFamilyIcons;
  font-style: normal;
  font-weight: normal;
}

@define-mixin Heading1A {
  @mixin FontTimesBold;
  color: $black;
  font-size: 3rem;
  font-stretch: normal;
  line-height: 3.6rem;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    line-height: 4.6rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
    line-height: 5.8rem;
  }
}

@define-mixin Heading1B {
  @mixin FontTimesBold;
  color: $black;
  font-size: 2.7rem;
  font-stretch: normal;
  line-height: 3.9rem;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    line-height: 5.4rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
    line-height: 6.5rem;
  }
}

@define-mixin Heading1Special {
  @mixin FontTimesBold;
  color: $black;
  font-size: 2.7rem;
  font-stretch: normal;
  line-height: 3.9rem;

  @media (min-width: $smBreakpoint) {
    font-size: 5rem;
    line-height: 6.5rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 8rem;
    line-height: 10.5rem;
  }
}

@define-mixin Heading2A {
  @mixin FontTimesBold;
  color: $black;
  font-size: 2.7rem;
  font-stretch: normal;
  line-height: 3.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 3.3rem;
    line-height: 4rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 4.2rem;
    line-height: 5.1rem;
  }
}

@define-mixin Heading2B {
  color: $black;
  @mixin FontTimesBold;
  font-size: 2.7rem;
  font-stretch: normal;
  line-height: 3.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.3rem;
    line-height: 2.9rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 3.3rem;
    line-height: 4rem;
  }
}

@define-mixin Heading3A {
  @mixin FontTimesBold;
  color: $black;
  font-size: 1.9rem;
  font-stretch: normal;
  line-height: 2.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.3rem;
    line-height: 2.9rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 3.3rem;
    line-height: 4rem;
  }
}

@define-mixin Heading3B {
  @mixin FontTimesBold;
  color: $black;
  font-size: 1.9rem;
  font-stretch: normal;
  line-height: 2.3rem;
}

@define-mixin Heading4 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  line-height: 1.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.7rem;
    letter-spacing: inherit;
    line-height: 2.2rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2rem;
    line-height: 2.7rem;
  }
}

@define-mixin Heading4B {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  line-height: 1.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.4rem;
    letter-spacing: inherit;
    line-height: 1.5rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2rem;
    line-height: 2.7rem;
  }
}

@define-mixin Heading5 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.7rem;
  line-height: 2.2rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 2.4rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2.7rem;
    line-height: 3.3rem;
  }
}

@define-mixin Heading6 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.9rem;
  line-height: 2.4rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 2.7rem;
    line-height: 3.3rem;
  }
}

@define-mixin Heading6White {
  @mixin Heading6;
  color: $white;
}

@define-mixin Heading6Red {
  @mixin Heading6;
  color: $red;
}

@define-mixin Heading7 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.9rem;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.7rem;
    line-height: 3.3rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 4rem;
    line-height: 5rem;
  }
}

@define-mixin Heading7White {
  @mixin Heading7;
  color: $white;
}

@define-mixin Heading7Red {
  @mixin Heading7;
  color: $red;
}

@define-mixin HeadingCatch1 {
  @mixin FontMontserratBold;
  color: $white;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1.3rem;
  text-shadow: $shadowA;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    text-shadow: 2px 2px 1px $black50;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 1.5rem;
    font-variant-numeric: lining-nums;
    line-height: 1.9rem;
    text-shadow: $shadowB;
  }
}

@define-mixin HeadingCatch2 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1.3rem;
  text-transform: uppercase;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.5rem;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 1.9rem;
  }
}

@define-mixin HeadingCatch3 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1.3rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 1.5rem;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 1.9rem;
  }
}

@define-mixin HeadingCatch4 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1.3rem;
  text-transform: uppercase;

  @media (min-width: $xlBreakpoint) {
    font-variant-numeric: lining-nums proportional-nums;
  }
}

@define-mixin ArrowText {
  @mixin FontMontserratBold;
  color: $white;
  font-size: 1.4rem;
  line-height: 1.8rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
}

@define-mixin ArrowTextHeroS {
  @mixin ArrowText;

  @media (min-width: $smBreakpoint) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
}

@define-mixin ArrowLabel {
  @mixin FontMontserratBold;
  color: $white;
  font-size: 1.4rem;
  line-height: 2.2rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}

@define-mixin HeadingOverview {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 2.3rem;
  letter-spacing: 2.5px;
  line-height: 2.3rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    letter-spacing: 6px;
    line-height: 4rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5.5rem;
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 10px;
    line-height: 5.5rem;
  }
}

@define-mixin HeadingOverviewDossier {
  @mixin FontMontserratBold;
  color: $white;
  font-size: 2.3rem;
  letter-spacing: 4px;
  line-height: 2.3rem;
  text-shadow: $shadowB;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    letter-spacing: 11px;
    line-height: 4rem;
    text-shadow: $shadowA;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 8.5rem;
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 15px;
    line-height: 8.5rem;
    text-shadow: $shadowA;
  }
}

@define-mixin HeadingOverviewCeleb {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.5rem;
  letter-spacing: 3px;
  line-height: 1.5rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 2.5rem;
    letter-spacing: 7px;
    line-height: 2.5rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 3.7rem;
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 11px;
    line-height: 3.7rem;
  }
}

@define-mixin SectionTitle {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.6rem;
  letter-spacing: 4px;
  line-height: 1.6rem;
  text-transform: uppercase;

  @media (min-width: $xlBreakpoint) {
    font-size: 2.4rem;
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 5px;
    line-height: 2.5rem;
  }
}

@define-mixin SectionTitleWhite {
  @mixin SectionTitle;
  color: $white;
}

@define-mixin SectionTitleSmall {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.3rem;
  letter-spacing: 3px;
  line-height: 1.3rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.5rem;
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 4px;
    line-height: 1.5rem;
  }
}

@define-mixin SectionTitleRelated {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.3rem;
  letter-spacing: 3px;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    letter-spacing: 4px;
    line-height: 1.9rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-variant-numeric: lining-nums proportional-nums;
  }
}

@define-mixin Lead1 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.7rem;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 3.3rem;
  }
}

@define-mixin Lead1White {
  @mixin Lead1;
  color: $white;
}

@define-mixin Lead2 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.7rem;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    color: $white;
    font-size: 1.9rem;
    line-height: 3.3rem;
    text-shadow: $shadowA;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2.6rem;
    line-height: 4.5rem;
  }
}

@define-mixin CatchQuestions {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.4rem;
  letter-spacing: 1px;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}

@define-mixin CatchQuestionsWhite {
  @mixin CatchQuestions;
  color: $white;
}

@define-mixin Paragraph {
  @mixin FontGeorgia;
  color: $black;
  font-size: 1.7rem;
  line-height: 2.7rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.8rem;
    line-height: 3.1rem;
  }
}

@define-mixin Paragraph2 {
  @mixin FontGeorgia;
  color: $black;
  font-size: 1.7rem;
  line-height: 2.7rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.8rem;
    line-height: 3.1rem;
  }
}

@define-mixin ParagraphBold {
  @mixin Paragraph;
  @mixin FontGeorgiaBold;
}

@define-mixin Paragraph2Bold {
  @mixin Paragraph2;
  @mixin FontGeorgiaBold;
}

@define-mixin CaptionAuthorParagraph {
  @mixin FontMontserrat;
  color: $black;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  line-height: 1.6rem;

  @media print {
    display: block;
    text-align: center;
  }
}

@define-mixin CaptionAuthorCopyRight {
  @mixin CaptionAuthorParagraph;
  color: $white;
  font-style: italic;

  @media print {
    display: block;
    text-align: center;
  }
}

@define-mixin CaptionAuthorCopyRightGray {
  @mixin CaptionAuthorCopyRight;
  color: $grayB;
}

@define-mixin CaptionAuthorCopyRightGrayC {
  @mixin CaptionAuthorCopyRight;
  color: $grayC;
}

@define-mixin CaptionTitleAuthorParagraph {
  @mixin CaptionAuthorParagraph;
  font-weight: 700;
}

@define-mixin CaptionAuthorBlog {
  @mixin CaptionAuthorParagraph;
  font-weight: 700;
}

@define-mixin CaptionTitleAuthorParagraph {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.7rem;
}

@define-mixin CaptionAuthorBlogWhite {
  @mixin FontMontserratBold;
  color: $redA;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 1rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

@define-mixin CaptionAuthorBlogRed {
  @mixin CaptionAuthorBlogWhite;
  color: $redA;
}

@define-mixin BreadcrumbsWords {
  @mixin FontMontserrat;
  color: $grayC;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

@define-mixin BreadcrumbsSymbol {
  @mixin FontMontserrat;
  color: $grayC;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

@define-mixin Quote {
  @mixin FontTimesBold;
  color: $black;
  font-size: 2.3rem;
  font-stretch: normal;
  line-height: 2.6rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.7rem;
    line-height: 3.1rem;
  }
}

@define-mixin QuoteAuthor {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.4rem;
  text-transform: uppercase;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.4rem;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 1.7rem;
  }
}

@define-mixin Tag {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.2rem;
  font-variant-numeric: lining-nums proportional-nums;
  letter-spacing: 3px;
  line-height: 1.8rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    letter-spacing: 5px;
    line-height: 2.3rem;
  }
}

@define-mixin InlineHover {
  border-bottom: 1px solid $grayB;
  color: $inherit;
  cursor: pointer;

  &:visited,
  &:link {
    color: $inherit;
  }

  @media (min-width: $smBreakpoint) {
    &:hover {
      border-color: $redA;
    }
  }
}

@define-mixin TeaserHover {
  border-bottom: 1px solid transparent;
  color: $inherit;
  cursor: pointer;

  &:visited,
  &:link {
    color: $inherit;
  }

  @media (min-width: $smBreakpoint) {
    &:hover {
      border-color: $redA;
    }
  }
}

@define-mixin Paging {
  @mixin FontMontserrat;
  color: $grayC;
  font-size: 1.5rem;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.8rem;
}

@define-mixin PagingActive {
  @mixin Paging;
  color: $black;
  font-weight: 700;
}

@define-mixin PagingSymbols {
  @mixin FontIcons;
  color: $grayC;
  font-size: 2.8rem;
  line-height: 2.8rem;
}

@define-mixin HeaderMenuItems {
  @mixin FontMontserrat;
  color: $white;
  font-size: 1.3rem;
  line-height: 1.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

@define-mixin HeaderMenuItemsBlack {
  @mixin HeaderMenuItemsWhite;
  color: $black;
}

@define-mixin HeaderMenuItemsSelectedBlack {
  @mixin HeaderMenuItemsBlack;
  font-weight: 700;
}

@define-mixin HeaderMenuItemsSelected {
  @mixin HeaderMenuItems;
  font-weight: 700;
}

@define-mixin MenuItems1 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 3.2rem;
  line-height: 6rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 4.2rem;
    line-height: 8rem;
  }
}

@define-mixin MenuItems2 {
  @mixin MenuItems1;
  font-family: $fontFamilyMontserrat;
}

@define-mixin MenuItems3 {
  @mixin FontMontserrat;
  color: $black;
  font-size: 1.7rem;
  line-height: 2rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

@define-mixin MenuItems3White {
  @mixin MenuItems3;
  color: $white;
}

@define-mixin MenuItems4 {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.7rem;
  line-height: 2rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

@define-mixin MenuItems4White {
  @mixin MenuItems4;
  color: $white;
}

@define-mixin SearchEntry {
  @mixin FontMontserrat;
  color: $black;
  font-size: 2.4rem;
  line-height: 6rem;

  @media (min-width: $smBreakpoint) {
    font-size: 3.2rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 4.2rem;
    line-height: 8rem;
  }
}

@define-mixin HeaderIconsTextBlack {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  line-height: 1rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1rem;
    line-height: 1.1rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 1.2rem;
    letter-spacing: 1px;
    line-height: 1.2rem;
  }
}

@define-mixin HeaderIconsTextWhite {
  @mixin HeaderIconsTextBlack;
  color: $white;
}

/* https://rasch.invisionapp.com/d/main#/console/13936005/392765386/preview */
@define-mixin Meta1 {
  @mixin FontMontserrat;
  color: $black;
  font-size: 1.4rem;
  line-height: 2rem;
}

@define-mixin Meta1Red {
  @mixin Meta1;
  color: $redA;
}

@define-mixin Meta1Bold {
  @mixin Meta1;
  color: $black;
  font-weight: 700;
}

@define-mixin Meta1BoldRedA {
  @mixin Meta1Bold;
  color: $redA;
}

@define-mixin Subheader {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 2rem;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
}

@define-mixin ButtonBigBlack {
  @mixin FontMontserratBold;
  color: $black;
  font-size: 1.9rem;
  line-height: 2.2rem;
}

@define-mixin ButtonBigWhite {
  @mixin ButtonBigBlack;
  color: $white;
}

@define-mixin ButtonBigRed {
  @mixin ButtonBigBlack;
  color: $redA;
}

@define-mixin ButtonSmallBlack {
  @mixin ButtonBigBlack;
  font-size: 1.5rem;
  line-height: 1.8rem;
}

@define-mixin ButtonSmallWhite {
  @mixin ButtonSmallBlack;
  color: $white;
}

@define-mixin ButtonSmallRed {
  @mixin ButtonSmallBlack;
  color: $redA;
}

@define-mixin FormLabelBigBlack {
  @mixin FontMontserrat;
  color: $black;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@define-mixin FormLabelBigGray {
  @mixin FormLabelBigBlack;
  color: $grayC;
}

@define-mixin FormLabelSmallBlack {
  @mixin FormLabelBigBlack;
  font-size: 1.4rem;
  letter-spacing: 0.4px;
  line-height: 1.6rem;
}

@define-mixin FormLabelSmallGray {
  @mixin FormLabelSmallBlack;
  color: $grayC;
}

@define-mixin FormLabelSmallRed {
  @mixin FormLabelSmallBlack;
  color: $redA;
}

@define-mixin LatestChannel {
  @mixin FontTimes;
  color: $black;
  font-size: 1.4rem;
  line-height: 2rem;
}

@define-mixin NumbersLarge {
  @mixin FontTimesBold;
  color: $white;
  font-size: 6rem;
  line-height: 6rem;

  @media (min-width: $smBreakpoint) {
    color: $black;
    font-size: 9rem;
    line-height: 9rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 15rem;
    line-height: 15rem;
  }
}

@define-mixin AdCaption {
  @mixin FontMontserrat;
  color: $grayC;
  font-size: 1.1rem;
  font-weight: 200;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

@define-mixin ILEIconFiles {
  display: inline;
  font-family: $fontFamilyIcons;
  margin-right: 1px;
  vertical-align: top;
}

/*
 *  Illustre - Buttons
 *  https://www.figma.com/file/TS9ySl2YVCWAqPuyyeDxZo/si_library_styleguide?node-id=0%3A17876
 */

@define-mixin ButtonBig {
  font-family: $fontFamilyMontserrat;
  font-size: 1.9rem;
  font-weight: 900;
  line-height: 2.2rem;
}

@define-mixin ButtonSmall {
  font-family: $fontFamilyMontserrat;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

@define-mixin ButtonBase {
  cursor: pointer;

  font-family: $fontFamilyMontserrat;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

@define-mixin ButtonBaseBig {
  @mixin ButtonBase;
  @mixin ButtonBig;
  font-size: 1.8rem;
  height: 54px;
  line-height: 2.2rem;
  padding: 16px 24px;
}

@define-mixin ButtonBaseSmall {
  @mixin ButtonBase;
  @mixin ButtonSmall;
  font-size: 1.4rem;
  height: 34px;
  line-height: 1.8rem;
  padding: 8px 16px;
}

@define-mixin ButtonPrimary {
  background: $gradientRed;
  border: none;
  border-radius: 4px;
  color: $white;

  &:hover {
    background: $redC;
  }
}

@define-mixin ButtonSecondary {
  background: $white;
  border: 1px solid $redA;
  border-radius: 4px;
  color: $redA;

  &:hover {
    background: $white;
    border: 1px solid $redA;
    color: $redC;
  }
}

@define-mixin ButtonTertiary {
  border: none;
  color: $redA;

  &:hover {
    color: $redC;
  }
}

/* Primary Buttons */

@define-mixin ButtonPrimaryBig {
  @mixin ButtonPrimary;
  @mixin ButtonBaseBig;
}

@define-mixin ButtonPrimarySmall {
  @mixin ButtonPrimary;
  @mixin ButtonBaseSmall;
}

/* Secondary Buttons */

@define-mixin ButtonSecondaryBig {
  @mixin ButtonSecondary;
  @mixin ButtonBaseBig;
}

@define-mixin ButtonSecondarySmall {
  @mixin ButtonSecondary;
  @mixin ButtonBaseSmall;
}

/* Tertiary Buttons */

@define-mixin ButtonTertiaryBig {
  @mixin ButtonTertiary;
  @mixin ButtonBaseBig;
}

@define-mixin ButtonTertiarySmall {
  @mixin ButtonTertiary;
  @mixin ButtonBaseSmall;
}

@define-mixin Info5 {
  color: $black;
  font-family: $fontFamilyMontserrat;
  font-size: 1rem;
  line-height: 1.4rem;
}

@define-mixin Info3 {
  color: $black;
  font-family: $fontFamilyMontserrat;
  font-size: 1.4rem;
  line-height: 1.9rem;
}

@define-mixin FormLabelBError {
  color: $redA;
  font-family: $fontFamilyMontserrat;
  font-size: 1.3rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
}

@define-mixin RichTextLink {
  &:not([href*='illustre.ch']) {
    &:not([href^='#']) {
      &:not([href^='/']) {
        &:not([href^='mailto:']) {
          &:not([href^='tel:']) {
            &:not([class*='teaser']) {
              &:not([class*='utility-button']) {
                &:not([class*='Icon']) {
                  &::after {
                    @mixin ILEIconFiles;
                    content: '\e94d';
                    margin-left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media print {
    border-bottom: none;
  }
}
