.InnerWrapper {
  align-items: center;
  background: color($grayA alpha(25%));
  display: flex;
  flex-direction: column;
  margin-bottom: $margin24;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.Text {
  /* use Paragraph as soon as mixings work */
  composes: FontGeorgia from 'typography.legacy.css';
  color: $black;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 2rem;
  padding: 0 15px 16px 12px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 3.2rem;
    padding: 0 120px 16px;
  }

  @media (min-width: $xlBreakpoint) {
    padding: 0 54px 16px;
  }
}

.Icon {
  animation: BellAnimation 500ms forwards;
  animation-delay: 500ms;
  color: $black;
  font-size: 5rem;
  line-height: 5rem;
  margin: $margin24 auto $margin16 auto;
  transform-origin: 50% 25%;

  @media (min-width: $smBreakpoint) {
    margin: $margin48 auto $margin24 auto;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 7rem;
    line-height: 7rem;
  }
}

.Wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: $margin56;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    background: color($grayA alpha(25%));
    margin-bottom: $margin40;
    padding-bottom: 48px;
  }
}

.Link {
  display: inline-block;
}

@mixin BellAnimation;
