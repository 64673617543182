.Wrapper {
  border-bottom: 1px solid $grayB;
  border-top: 1px solid $grayB;
  display: flex;
  line-height: 1;
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 10px;
  }
}

.ImageWrapper {
  float: left;
  margin-bottom: auto;
  margin-right: 10px;
  margin-top: auto;
  position: relative;
  width: 60px;
}

.Image {
  height: auto;
  width: 100%;
}

.Link {
  display: flex;
}

.ContentWrapper {
  float: left;
  min-height: 90px;
  position: relative;
  text-align: left;
  width: calc(100% - 70px); /* minus imagewidth (60px + 10px margin-right) */

  @media (min-width: $mdBreakpoint) {
    width: 80%;
  }
}

.ShortTitle {
  composes: CaptionAuthorParagraph from 'typography.legacy.css';
  margin-bottom: 9px;
  margin-top: 1px;
}

.Title {
  composes: CaptionTitleAuthorParagraph from 'typography.legacy.css';
  margin-top: 12px;
}

.Button {
  margin-bottom: 12px;
}

.SpecialWrapper {
  min-height: 16px;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 100%;
  z-index: calc($zIndex1 + 1);

  @media (min-width: $smBreakpoint) {
    min-height: 14px;
  }
}

.SpecialImage {
  max-height: 18px;
  max-width: 55px;

  @media (min-width: $smBreakpoint) {
    max-height: 14px;
  }
}

.PartnerWrapper {
  height: 20px;
  margin-top: 5px;
  padding-bottom: 44px;
  text-align: right;

  @media (width >= 480px) {
    min-height: 25px;
  }

  @media (min-width: $smBreakpoint) {
    text-align: right;
  }
}

.PartnerImage {
  max-height: 28px;
  max-width: 46px;
  width: auto;

  @media (min-width: $smBreakpoint) {
    max-height: 30px;
    max-width: 50px;
  }
}

.SponsorContentWrapper {
  float: left;
  height: 90px;
  padding-left: 5px;
  position: relative;
  text-align: left;
  width: 62px;

  @media (min-width: $smBreakpoint) {
    width: 62px;
  }

  @media (min-width: $mdBreakpoint) {
    text-align: right;
    width: 85px;
  }
}

.SponsorWrapper {
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: calc($zIndex1 + 1);

  @media (min-width: $xlBreakpoint) {
    height: 10px;
  }
}

.SponsorItem {
  composes: CaptionAuthorParagraph from 'typography.legacy.css';
  display: block;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;

  > .Separator {
    display: none;
  }
}
