.Wrapper {
  background: $black;
  color: $white;
  display: flex;
  height: calc($menuHeaderHeightXs + 20px);
  padding: 12px 0 15px;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    height: $menuHeaderHeightSm;
    padding: 0;
  }

  @media (min-width: $xlBreakpoint) {
    height: $menuHeaderHeightXl;
  }

  .Logo {
    margin-right: $margin16;
  }
}

.LogoWrapper {
  display: block;
  float: left;
  height: 37px;
  width: 117px;

  @media (min-width: $smBreakpoint) {
    height: 46px;
    width: 149px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 64px;
    width: 207px;
  }
}

.SVGLogo {
  max-height: 100%;
  max-width: 100%;
}

.Content {
  composes: Navigation from 'App/components/HeaderArea/styles.legacy.css';
}

.IconWrapper {
  align-items: center;
  display: flex;
}

.Header {
  composes: Header from 'App/components/Header/components/HeaderInner/styles.legacy.css';
}

.HeaderMenuItems {
  composes: HeaderMenuItemsWhite from 'typography.legacy.css';

  @media (min-width: $xlBreakpoint) {
    margin-left: 15px; /* the navigation is not in the grid so we have to add this margin to match designs :-( */
  }
}

.MenuButton {
  border: none;
  cursor: pointer;
  left: 10px;
  line-height: 1;
  padding: 0;
  position: relative;
}

.Icon {
  &::before {
    color: $white;
    display: inline-block;
    font-size: 3.5rem;
    font-weight: 600;
    transition: color 0.3s ease-in-out;

    @media (min-width: $smBreakpoint) {
      font-size: 4rem;
    }

    @media (min-width: $xlBreakpoint) {
      font-size: 5rem;
    }
  }

  &:hover {
    &::before {
      color: $redA;
    }
  }
}
