.Form {
  margin: $margin40 0 60px;
}

.FormWrapper,
.FormWrapperVisible {
  height: auto;
  overflow: hidden;
  transition: max-height 300ms linear, opacity 300ms linear;
}

.FormWrapper {
  max-height: 0;
  opacity: 0;
}

.FormWrapperVisible {
  max-height: 999px;
  opacity: 1;

  @media (min-width: $smBreakpoint) {
    margin-left: 30px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-left: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-left: 50px;
  }
}
