.TeaserWrapper {
  margin-bottom: 30px;

  @media (min-width: $smBreakpoint) {
    border: 1px solid;
    border-color: $redA;
    display: block;
    margin-bottom: $margin40;
    min-height: 265px;
    overflow-wrap: break-word;
    position: relative;
    word-break: break-word;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;

    min-height: 390px;
  }
}

.ContentWrapper {
  margin: 20px 34px 0 238px;

  @media (min-width: $xlBreakpoint) {
    margin: 37px 55px 0 323px;
  }
}

.ShortTitle {
  composes: HeadingCatch2 from 'typography.legacy.css';
  color: $redA;
  margin-bottom: 11px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 9px;
  }
}

.Title {
  composes: Heading7 from 'typography.legacy.css';
  color: $redA;
  margin-bottom: 14px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 15px;
  }
}

.Lead {
  composes: Paragraph2 from 'typography.legacy.css';
  color: $redA;
  margin-bottom: 76px;
  white-space: pre-wrap;

  & > strong {
    @mixin Paragraph2Bold;
    color: $redA;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin96;
  }
}

.BannerWrapper {
  align-items: center;
  background: $gradientRed;
  bottom: 0;
  display: flex;
  height: 50px;
  left: 0;
  position: absolute;
  width: 100%;
  
  @media (min-width: $xlBreakpoint) {
    height: 65px;
  }
}

.Image {
  bottom: 16px;
  box-shadow: $shadowC;
  height: 228px;
  left: 34px;
  position: absolute;
  width: 168px;

  @media (min-width: $xlBreakpoint) {
    bottom: 22px;
    height: 327px;
    left: 30px;
    width: 241px;
  }
}

.ArrowButtonStyle {
  margin-left: 248px;
  padding-left: 19px;
  padding-right: 19px;

  @media (min-width: $xlBreakpoint) {
    margin-left: 333px;
  }
}
