.menuOverlay {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, auto);

    .Item {
      margin-bottom: 30px;
    }
    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
  }

  @media (min-width: $smBreakpoint) {
    .Grid0 {
      .Item {
        margin-bottom: $margin40;
      }
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      .Item {
        margin-bottom: 50px;
      }
    }
  }
}
