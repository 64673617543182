.SectionTitle {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
  word-break: break-word;

  &::before,
  &::after {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    content: '';
    display: inline-block;
    width: 50px;

    @media (min-width: $xlBreakpoint) {
      width: 100px;
    }
  }

  &::before {
    margin-right: 15px;
  }

  &::after {
    margin-left: 10px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 10px;
  }
}

.TitleLink {
  composes: SectionTitle from 'typography.legacy.css';
  color: $black;
  display: flex;

  &:hover {
    color: $redA;
    
    .SVGImage {
      filter: drop-shadow($shadowB);
    }
  }

  .SVGImage {
    @media (max-width: $xsBreakpointTo) {
      max-width: 140px;
    }

    &:global(.landscape) {
      height: 18px;

      @media (min-width: $smBreakpoint) {
        height: 20px;
      }

      @media (min-width: $xlBreakpoint) {
        height: 25px;
      }
    }

    &:global(.portrait),
    &:global(.square) {
      height: 30px;

      @media (min-width: $smBreakpoint) {
        height: 40px;
      }

      @media (min-width: $xlBreakpoint) {
        height: 50px;
      }
    }
  }
}

.TitleWrapper {
  margin-bottom: 10px;
}

.SponsorWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.SponsorImage {
  max-height: 40px;
  max-width: 140px;
}

.SponsorPrefix {
  composes: BreadcrumbsWords from 'typography.legacy.css';
  margin-bottom: 3px;
}

.SponsorLink {
  display: block;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 10px;
  }
}
