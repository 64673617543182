@define-mixin aspectRatio {
  position: relative;

  &::before {
    content: ' ';
    display: block;
  }
}

@define-mixin aspectRatio3x2 {
  @mixin aspectRatio;

  &::before {
    padding-top: 66.6666666666%;
  }
}

@define-mixin aspectRatio16x9 {
  @mixin aspectRatio;
  &::before {
    padding-top: 56.5%;
  }
  @media (min-width: $smBreakpoint) {
    &::before {
      padding-top: 56.25%;
    }
  }
}
