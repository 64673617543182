.Wrapper {
  composes: TextWrapper from 'Paragraphs/components/ImageCaption/styles.legacy.css';
  composes: CaptionAuthorParagraph from 'typography.legacy.css';
  composes: Margin0 from 'helpers.legacy.css';

  margin-bottom: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 5px;
}

.Credit {
  composes: CaptionAuthorCopyRightGray from 'typography.legacy.css';
}
