.Body {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.Name {
  composes: ParagraphBold from 'typography.legacy.css';
  flex: 1 1 50%;
}

.Date {
  composes: CaptionAuthorParagraph from 'typography.legacy.css';
  color: $grayB;
  flex: 1 1 50%;

  /**
  * to match the height of the name
  */
  line-height: 2.5rem;
  text-align: right;

  @media (min-width: $smBreakpoint) {
    /**
    * to match the height of the name
    */
    line-height: 3.5rem;
  }
}

.Text {
  composes: Paragraph from 'typography.legacy.css';
  flex: 0 0 100%;
  font-weight: 300;
  margin-top: 20px;

  @media (min-width: $smBreakpoint) {
    margin-top: 30px;
  }
}

.LogoWrapper {
  height: 18px;
  width: 45px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 9px;
  }
}

.AccountNameWrapper {
  align-items: center;
  display: flex;
}

.AccountName {
  composes: ParagraphBold from 'typography.legacy.css';
  color: $redA;
  margin-left: $margin8;
}
