.Icon {
  composes: Icon from 'raschFont.legacy.css';
  composes: IconFullscreenButton from 'raschFont.legacy.css';
  color: $white;
  cursor: zoom-in;
  filter: drop-shadow(0 0 4px $black);
  font-size: 4rem;
  inset: 0;
  opacity: 0.6;
  padding: 10px;
  position: absolute;
  text-align: right;
  transition: opacity 0.3s;
  z-index: calc($zIndex50 - 1);

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
  }

  &:focus {
    outline: none;
  }
}
