.Link {
  composes: Link from 'UtilityBar/components/UtilityLink/styles.legacy.css'
}

.LinkHover {
  composes: LinkHover from 'UtilityBar/components/UtilityLink/styles.legacy.css'
}

.LinkLight {
  composes: LinkLight from 'UtilityBar/components/UtilityLink/styles.legacy.css'
}

.Icon {
  composes: Icon from 'UtilityBar/components/UtilityLink/styles.legacy.css'
}

.Label {
  composes: Label from 'UtilityBar/components/UtilityLink/styles.legacy.css'
}

.LinkOverlay {
  composes: LinkOverlay from 'UtilityBar/components/UtilityLink/styles.legacy.css'
}

.Animating {
  animation: BookmarkAnimation 0.5s;
}

@mixin BookmarkAnimation;

.Restricted {
  .Label,
  .Icon {
    color: $grayB;
    fill: $grayB;
    transition: color 0.3s, fill 0.3s;
  }
}
