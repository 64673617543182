.BookmarkButtonWrapper {
  align-items: center;
  background: $transparent;
  border: 1px solid $grayB;
  box-shadow: 0 15px 30px -20px $black50;
  display: flex;
  height: 22px;
  justify-content: center;
  margin-left: 4px;
  outline: none;
  padding: 0;
  width: 98px;

  @media screen and (min-width: $smBreakpoint){
    margin-left: 0;
  }

  &:hover {
    border: 1px solid $redA;
    cursor: pointer;

    .Text {
      color: $redA;
    }

    .Icon {
      color: $redA;
    }
  }
}

.Text {
  composes: ArrowText from 'typography.legacy.css';
  color: $black;
}

.Icon {
  font-size: 1.7rem;
  margin-right: $margin4;
  transform-origin: 50% 25%;
}

.Animating {
  animation: BookmarkAnimation 500ms;
}

@mixin BookmarkAnimation;
