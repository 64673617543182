.Wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;

  @media (min-width: $smBreakpoint) {
    display: block;
    margin: 0 -$margin24;
    position: relative;
  }

  &.Header {
    display: flex;
    justify-content: center;
    top: 4px;

    @media (min-width: $xlBreakpoint) { 
      top: 8px;
    }
  }
}

.WrapperOverlay {
  display: block;
  height: auto;
  margin: 0 -$margin24;
}
