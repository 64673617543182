.SelectField {
  composes: FormLabelBigBlack from 'typography.legacy.css';
  background-color: $grayF;
  border-bottom: 1px solid $grayA;
  border-left: 0;
  border-radius: 4px 4px 0 0;
  border-right: 0;
  border-top: 0;
  display: block;
  height: 54px;
  margin: 0;
  padding: 16px 12px;
  position: relative;
  transition: border-color 300ms ease-in-out;
  width: 100%;

  &:not(.Disabled):hover {
    border-color: $black;
  }

  &.HasError {
    border-color: $redA;
  }

  &.SelectFieldMobile {
    padding: 24px 12px 8px;
  }
}

.SelectFieldIcon {
  float: right;
  font-size: 1.8rem;
  line-height: 2.2rem;
  position: relative;
  z-index: 0;

  &::before {
    color: $grayC;
  }
}

.SelectFieldDesktopWrapper {
  position: relative;
}

.OptionItemsWrapper {
  background-color: $white;
  border: 1px solid $grayA;
  border-top: none;
  display: block;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 86px;
  transform: translateY(-32px);
  width: 100%;
  z-index: calc($zIndex1 + 1);
}

.OptionItem {
  composes: FormLabelBigGray from 'typography.legacy.css';
  background-color: $white;
  border: none;
  cursor: pointer;
  display: block;
  height: 53px;
  padding: 0 23px 0 12px;
  text-align: left;
  transition: background-color 200ms ease-in-out;
  width: 100%;

  &:not(.Disabled):hover {
    background-color: $grayE;
  }
}

.MobileIcon {
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 18px;
}

.MobileLabel {
  composes: FormLabelBigGray from 'typography.legacy.css';
  pointer-events: none;
  position: absolute;
  transform: translate(12px, 16px);
  z-index: 1;

  &.MobileLabelAbove {
    transform: scale(0.7) translate(17px, 8px);
    transform-origin: top left;
    transition: transform 0.3s;
  }
}

.Label {
  composes: FormLabelBigGray from 'typography.legacy.css';
  position: absolute;

  &.LabelAbove {
    transform: scale(0.7) translate(-20%, -80%);
    transition: transform 0.3s;

    &.HasError {
      color: $redA;
    }
  }
}

.SelectedValue {
  display: inline-block;
  padding-top: 10px;
}

.ErrorIcon {
  color: $redA;
  float: right;
  font-size: 18px;
  line-height: 2.2rem;
  margin-right: $margin8;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 16px;
}

.ErrorMessage,
.HelperText {
  padding-left: 12px;
}

.Disabled {
  .SelectFieldIcon::before {
    color: $grayB; 
  }

  & + .MobileIcon::before {
    color: $grayB; 
  }
}

.OptionsFilter {
  composes: FormLabelBigGray from 'typography.legacy.css';
  background-color: $inherit;
  border: none;
  color: $grayC;
  height: 100%;
  outline: none;
  width: 100%;
}

.OptionsFilterIcon {
  float: right;
  font-size: 3rem;
  line-height: 2.2rem;
  margin-right: $margin8;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 18px;
}

.OptionsEmpty {
  display:flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
