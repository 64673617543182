.Wrapper {
  background: $white;
  height: $headerHeightXs;
  margin: 0 auto;
  max-width: 100%;
  padding: $headerMarginXs 0;
  position: relative;
  transition: padding-bottom 0.3s;
  z-index: calc($zIndexHeader + 1);

  @media (min-width: $smBreakpoint) {
    height: $headerHeightSm;
    padding: $headerMarginLg 0;
  }

  @media (min-width: $xlBreakpoint) {
    height: $headerHeightXl;
    padding: $headerMarginXl 0;
  }

  @media (min-width: $xxlBreakpoint) {
    margin-left: 0;
  }

  &Border {
    &::after {
      background-color: $grayA;
      bottom: 0;
      content: '';
      display: inline-block;
      height: 1px;
      left: -50%;
      position: absolute;
      width: 200vw;
    }
  }

  &.MarketingPage {
    padding: 8px 0;
  }
}

.ContainerWrapper {
  background-color: $white;
}

:global(.branding-day) {
  .Wrapper {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    @media (min-width: $mdBreakpoint) {
      left: 0;
      margin: 0 auto;
      right: 0;
      width: $mdBreakPointBrandingDayContainerWidth;

      &Border {
        &::after {
          left: 0;
          width: $mdBreakPointBrandingDayContainerWidth;
        }
      }
    }

    @media (min-width: $xlBreakpoint) {
      width: $xlBreakPointBrandingDayContainerWidth;

      &Border {
        &::after {
          left: 0;
          width: $xlBreakPointBrandingDayContainerWidth;
        }
      }
    }
  }

  .UtilityBarWrapper {
    @media (min-width: $mdBreakpoint) {
      margin: 0 auto;
    }

    @media (min-width: 1200px) {
      width: $mdBreakPointBrandingDayContainerWidth;
    }

    @media (min-width: $xlBreakpoint) {
      width: $xlBreakPointBrandingDayContainerWidth;
    }
  }

  .WrapperCollapsed {
    &.Fixed {
      top: 0;
    }
  }
}

.WrapperCollapsed {
  height: auto;
  margin: 0 auto;
  max-width: inherit;
  padding: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: $zIndexHeader;

  .Header {
    .Logo {
      margin: 0;
    }
  }

  &.Fixed {
    position: fixed;

    @media print {
      position: static;
    }
  }
}

/* NavigationMenu/MenuHeader is compsing from this class */
.Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  &.MarketingPage {
    justify-content: center;
  }
}

.BackButtonWrapper {
  display: flex;
  height: 36px;
  width: 50%;

  @media (min-width: $smBreakpoint) {
    height: 46px;
    min-width: 50px;
    width: auto;
  }
}

.SVGLogo {
  max-height: 100%;
  max-width: 100%;
}

.LogoWrapper {
  display: block;
  float: left;
  height: 37px;
  width: 117px;

  @media (min-width: $smBreakpoint) {
    height: 46px;
    width: 149px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 64px;
    width: 207px;
  }

  @media print {
    -webkit-print-color-adjust: exact; /* stylelint-disable-line */
  }
}

.Navigation {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.Icons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 50%;

  @media (min-width: $smBreakpoint) {
    width: auto;
  }
}

.IconText {
  composes: HeaderIconsText from 'typography.legacy.css';
  display: block;
  position: relative;
  top: 0;
  transition: color 0.3s ease-in-out;

  @media (min-width: $smBreakpoint) {
    margin-top: 1px;
    top: 1px;
  }

  @media (min-width: $mdBreakpoint) {
    top: 0;
  }

  @media (min-width: $xlBreakpoint) {
    top: 2px;
  }
}

$iconFontSizeXs: 1.8rem;
$iconFontSizeMd: 2.4rem;
$iconFontSizeXl: 3.2rem;

.Icon {
  align-items: center;
  display: flex;
  flex-direction: column;

  > svg {
    height: 19px;
    max-height: 33px;
    max-width: 33px;
    width: 19px;

    @media (min-width: $smBreakpoint) {
      height: 19px;
      width: 19px;
    }

    @media (min-width: $mdBreakpoint) {
      height: 25px;
      width: 25px;
    }

    @media (min-width: $xlBreakpoint) {
      height: 33px;
      width: 33px;
    }

    path {
      transition: fill 0.3s ease-in-out;
    }

    &:hover {
      fill: $black;

      @media (min-width: $xlBreakpoint) {
        fill: $redA;
      }
    }
  }

  &::before {
    color: $black;
    font-size: $iconFontSizeXs;
    transition: color 0.3s ease-in-out;

    @media (min-width: $smBreakpoint) {
      margin-bottom: 2px;
    }

    @media (min-width: $mdBreakpoint) {
      font-size: $iconFontSizeMd;
    }

    @media (min-width: $xlBreakpoint) {
      font-size: $iconFontSizeXl;
      margin-bottom: 5px;
    }
  }
}

.Link {
  cursor: pointer;
  margin: 0 $margin8;
  outline: none;

  @media (min-width: $smBreakpoint) {
    margin: 0 11px;

    &:hover {
      svg path,
      .IconText,
      .Icon::before {
        color: $redA;
        fill: $redA;
      }
    }
  }

  @media (min-width: $xlBreakpoint) {
    margin: 0 18px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.MenuButton {
  border: none;
  cursor: pointer;
  line-height: 1;
  padding: 0;
}

.BackButton {
  justify-content: center;
  text-align: left;

  svg {
    height: 16px;
    width: 16px;
  }
}

.UtilityBarWrapper {
  @mixin hideforPrint;
  background-color: $white;
  border-bottom: 1px solid $grayA;
  display: none;
  height: $utilityBarHeight;
  position: absolute;
  top: -100px;
  transition: transform 0.3s ease-in-out;
  width:100%;
  z-index: -100;

  @media (min-width: $smBreakpoint) {
    display: block;
  }

  @media (min-width: $xlBreakpoint) {
    height: 64px;
  }
}

.UtilityBarWrapperMove {
  top: -100%;
  
  @media (min-width: $smBreakpoint) {
    transform: translateY(100%);
  }
}

/* stylelint-disable-next-line */
.WrapperBorder {
  /* used for typedef since &Border is not recognized */
}
