.Title {
  composes: MenuItems3 from 'typography.legacy.css';
  display: inline-block;
  transition: color 0.3s ease-in-out;
  width: 100%;

  &:hover {
    color: $redA;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin16;
    margin-top: $margin16;
  }
}

.Icon {
  font-size: 1.9rem;
  margin-right: 12px;
  position: relative;
  top: 4px;

  @media (min-width: $xlBreakpoint) {
    font-size: 2.7rem;
    margin-right: $margin16;
    top: 6px;
  }
}
