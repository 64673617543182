.Parallax {
  @media (min-width: $mdBreakpoint) {
    backface-visibility: hidden;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 595px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 666px;
  }
}

.ImageDefault {
  width: 100%;
}

.CreditsTitle {
  composes: CaptionAuthorCopyRightGray from 'typography.legacy.css';
  display: block;
  text-align: right;


  @media (min-width: $mdBreakpoint) {
    margin-right: 0;
    margin-top: $defaultMargin;
  }
}
