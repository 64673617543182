.Link {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  outline: none;
  padding: 0 27px 5px;
  position: relative;

  &.Active,
  &:hover {
    p,
    span:not(.CommentCount)
    svg,
    path {
      transition: color 0.3s, fill 0.3s;
    }
  }

  @media (min-width: $xlBreakpoint) {
    padding: 0 28px 8px;
  }
}

.LinkHover {
  &.Active,
  &:hover {
    p,
    span:not(.CommentCount)
    svg,
    path {
      color: $redA;
      fill: $redA;
    }
  }
}

.LinkOverlay {
  margin-bottom: $margin24;
  padding: 0 25px 8px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin16;
    padding: 0 30px 8px;

    .Icon {
      height: 22px;
      width: 22px;
    }
  }
}

.LinkOverlayInlined {
  margin-bottom: $margin24;
  margin-top: $margin8;
  padding: 0 24px 8px 23px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin16;
    padding: 0 30px 8px;

    svg {
      height: 26px;
      width: 22px;
    }
  }
}

.LinkLight {
  p,
  svg {
    color: $white;
    fill: $white;
  }
}

.Icon {
  display: flex;
  height: 20px;
  position: relative;
  width: 20px;

  @media (min-width: $smBreakpoint){
    margin-bottom: 3px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 28px;
    width: 30px;
  }
}

.CommentCount {
  composes: HeaderIconsTextWhite from 'typography.legacy.css';
  display: inline-block;
  font-variant-numeric: normal;
  position: relative;
  top: 1px;
  width: 100%;
  z-index: 1;

  @media (min-width: $smBreakpoint) {
    top: 1px;
  }
  @media (min-width: $xlBreakpoint) {
    left: -1px;
    letter-spacing: 0;
    top: 6px;
  }
}

.Badge {
  border-radius: 50%;
  content: '';
  height: 17px;
  left: 50%;
  line-height: 17px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-58%,0%);
  width: 17px;
  z-index: 0;

  &.Restricted {
    background-color: $grayB;
  }
}

.Label {
  composes: HeaderIconsText from 'typography.legacy.css';
  bottom: -8px;
  left: -5px;
  position: absolute;
  right: -5px;
  text-align: center;
  white-space: nowrap;
}

.Restricted {
  .Label,
  .Icon {
    color: $grayB;
    fill: $grayB;
    transition: color 0.3s, fill 0.3s;
  }
}
