$smImageWidth: 70%;
$mdImageWidth: 75%;

.Wrapper {
  display: inline-block;
  line-height: 0;
  margin: 0 -$outerGapXs 19px;
  position: relative;
  width: calc(100% + $outerGapXs * 2);

  @media (min-width: $smBreakpoint) {
    margin: 0 0 $margin16;
    width: 100%;
  }

  @media (min-width: $xlBreakpoint) {
    margin: 0 0 43px;
  }

  &::before {
    background: $gradientTeaser;
    bottom: 0;
    content: '';
    height: 140px;
    left: 0;
    position: absolute;
    right: 0;

    @media (min-width: $smBreakpoint) {
      height: 100%;
      width: $smImageWidth;
    }

    @media (min-width: $mdBreakpoint) {
      width: $mdImageWidth;
    }
  }

  &:hover {
    .Title .TitleInner {
      box-shadow: $shadowH;
    }
  }
}

.Image {
  height: 100%;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    max-width: $smImageWidth;
    width: $smImageWidth;
  }

  @media (min-width: $mdBreakpoint) {
    max-width: $mdImageWidth;
    width: $mdImageWidth;
  }
}

.ContentWrapper {
  bottom: -22px;
  left: $outerGapXs;
  position: absolute;
  right: 15px;
  word-wrap: break-word;
  z-index: calc($zIndex1 + 1);

  @media (min-width: $smBreakpoint) {
    left: auto;
    margin-top: 20px;
    right: 10px;
    top: 0;
    width: 390px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-top: 120px;
    width: 533px;
  }
}

.Title {
  composes: Heading1B from 'typography.legacy.css';
  display: inline;

  @media (min-width: $smBreakpoint) {
    line-height: 5.65rem;
  }

  @media (min-width: $xlBreakpoint) {
    line-height: 7rem;
  }

  > .TitleInner {
    background-color: $redA;
    box-decoration-break: clone;
    box-shadow: $shadowG;

    color: $white;
    padding: 0 6px 2px;
    transition: box-shadow 0.3s;

    @media (min-width: $smBreakpoint) {
      background-color: $redA;
      padding: 0 10px 3px;
    }

    @media (min-width: $xlBreakpoint) {
      background: $gradientRed;
    }
  }
}

.ShortTitle {
  composes: HeadingCatch1 from 'typography.legacy.css';
  padding: 1px 6px;

  @media (min-width: $smBreakpoint) {
    color: $black;
    margin-left: auto;
    max-width: 60%;
    padding: 0 6px 6px;
    text-shadow: none;
  }

  @media (min-width: $smBreakpoint) {
    max-width: 49.3%;
  }

  @media (min-width: $mdBreakpoint) {
    max-width: 45%;
  }

  @media (min-width: $xlBreakpoint) {
    max-width: 53.6%;
  }
}

.Badge {
  margin-bottom: 3px;
  margin-left: auto;
  padding-left: 6px;
  white-space: nowrap;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 10px;
    max-width: 49.3%;
  }

  @media (min-width: $mdBreakpoint) {
    max-width: 45.2%;
  }

  @media (min-width: $xlBreakpoint) {
    max-width: 53.6%;
  }
}

.IconStyle {
  color: $white;
  display: inline-block;
  font-size: 1.2rem;
  left: 6px;
  position: relative;
  text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  top: -4px;

  @media (min-width: $smBreakpoint) {
    color: $black;
    font-size: 1.5rem;
    left: 52.2%;
    text-shadow: none;
    top: -10px;
  }

  @media (min-width: $mdBreakpoint) {
    left: 56.3%;
    top: -10px;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2.5rem;
    left: 47.5%;
  }
}

.IconMovieOutlineStyle {
  composes: IconMovieOutlineStyle from 'App/components/Teaser/styles.legacy.css';
  font-size: 5rem;
  left: 50%;

  @media (min-width: $smBreakpoint) {
    font-size: 6rem;
    left: calc($smImageWidth / 2);
  }

  @media (min-width: $mdBreakpoint) {
    left: calc($mdImageWidth / 2);
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 8rem;
  }
}
