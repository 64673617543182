.Wrapper {
  margin-bottom: $margin40;
  overflow: hidden;
  position: relative;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 60px;
    overflow: unset;
  }
}

.HeaderBackground {
  height: 242px;
  margin-bottom: -242px;
  z-index: $zIndexBehindContent;

  @media (min-width: $mdBreakpoint) {
    height: 343px;
    margin-bottom: -343px;
  }
}
