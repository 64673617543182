.AlertsProfileWrapper {
  margin-bottom: $margin40;
  margin-top: $margin24;

  @media (min-width: $smBreakpoint) {
    margin-top: 27px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 29px;
  }
}

.LoginWrapper {
  display: flex;
}

.Title {
  composes: HeadingOverview from 'typography.legacy.css';
  margin-bottom: $margin24;
  text-align-last: left;
}

.Description {
  /* use Paragraph as soon as mixings work */
  composes: FontGeorgia from 'typography.legacy.css';
  color: $black;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: $margin40;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 3.2rem;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin64;
  }
}
