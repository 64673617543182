.Wrapper {
  display: inline-block;
  line-height: 1;
  position: relative;
  width: 100%;

  &:hover {
    border-bottom-color: $redA;
  }

  &::after {
    background: $gradientTeaser;
    content: '';
    display: inline-block;
    inset: 0;
    position: absolute;
  }
}

.Button {
  bottom: 38px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}

.Image {
  height: auto;
  width: 100%;
}

.ContentWrapper {
  inset: 0;
  position: absolute;
  text-align: center;
  z-index: $zIndex1;
}

.Title {
  composes: Heading6White from 'typography.legacy.css';
  bottom: 62px;
  display: inline;
  left: 0;
  margin: auto;
  position: absolute;
  width: 100%;

  @media (min-width: $xlBreakpoint) {
    bottom: 72px;
    left: 0;
    margin: 0;
    width: 100%;
  }
}

.SpecialWrapper {
  margin-top: 20px;
  min-height: 30px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    min-height: 36px;
  }

  @media (min-width: $xlBreakpoint) {
    min-height: 60px;
  }
}

.SpecialImage {
  height: 100%;
  max-height: 30px;
  max-width: 162px;

  /* behavior breakpoint not in style guide */
  @media (width >= 375px) {
    max-height: 50px;
  }

  /* behavior breakpoint not in style guide */
  @media (width >= 480px) {
    max-height: 80px;
  }

  @media (min-width: $smBreakpoint) {
    max-height: 36px;
    max-width: 156px;
  }

  @media (min-width: $xlBreakpoint) {
    max-height: 60px;
    max-width: 240px;
  }
}

.PartnerWrapper {
  height: 20px;
  margin-top: 5px;
  text-align: right;

  @media (width >= 480px) {
    min-height: 25px;
  }

  @media (min-width: $xlBreakpoint) {
    min-height: 30px;
  }
}

.PartnerImage {
  background-color: $white;
  max-height: 20px;
  max-width: 162px;
  width: auto;

  @media (min-width: $smBreakpoint) {
    max-height: 25px;
    max-width: 156px;
  }

  @media (min-width: $xlBreakpoint) {
    max-height: 30px;
    max-width: 240px;
  }
}

.SponsorWrapper {
  composes: CaptionAuthorCopyRight from 'typography.legacy.css';
  bottom: 0;
  color: $white;
  left: 0;
  min-height: 21px;
  padding: 5px;
  position: absolute;
  right: 0;
  text-align: center;
}
