.Wrapper {
  align-items: center;
  border: 2px dashed $grayA;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 16px 16px 0;
  position: relative;
  transition: border .24s ease-in-out;
}

.WrapperReject {
  border-color: $redA;
}

.WrapperActive {
  border-color: $grayC;
}

.Title {
  @mixin FormLabelBigBlack;
  display: none;  /* don't display on mobile */
  margin-bottom: 19px;

  @media (min-width: $smBreakpoint) {
    display: block;
  }
}

.Subtitle {
  @mixin FormLabelSmallGray;
  display: none; /* don't display on mobile */
  

  @media (min-width: $smBreakpoint) {
    display: block;
  }
}

.FileExtensions {
  @mixin FormLabelSmallGray;
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.FullSpaceIconWrapper {
  margin-bottom: $margin12;
  margin-top: $margin24;
  pointer-events: none;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin24;
    margin-top: 42px;
  }
}

.UploadIconWrapper {
  pointer-events: none;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin12;
    margin-top: $margin24;
  }
}

.Info {
  composes: Info3 from 'typography.legacy.css';
  margin-bottom: $margin24;
  margin-top: $margin12;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin24;
  }
}

.ErrorMessage {
  composes: FormLabelBError from 'typography.legacy.css';
  color: $redA;
  display: block;
  margin-left: $margin12;
  margin-top: $margin4;
}

.FilesListWrapper {
  list-style-type: none;
  margin-bottom: $margin16;
  width: 100%;
}

.FilesListWrapperError {
  margin-bottom: $margin16;
}

.UploadButtonWrapper {
  button {
    margin: 0;
  }

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin48;

    button {
      margin: 0;
    }
  }
}

.WrapperAccept {
  border-color: $grayC;
}
