.Wrapper {
  align-items: center;
  border-bottom: 1px solid $bg;
  border-top: 1px solid $bg;
  display: flex;
  margin-bottom: 20px;
  margin-top: 2px;
  padding: 11px 0;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 10px;
    margin-top: 7px;
    padding: 7px 0;
  }
}

.FlexOne {
  flex: 1;
}

.AuthorImg {
  border: 1px solid $bg;
  border-radius: 50%;
  height: 50px;
  margin-right: 15px;
  padding: 2px;
  width: 50px;

  @media (min-width: $smBreakpoint) {
    margin-right: 0;
  }
}

.AuthorName {
  composes: CaptionTitleAuthorParagraph from 'typography.legacy.css';
}

.AuthorDescription {
  composes: CaptionAuthorParagraph from 'typography.legacy.css';
}

.AuthorText {
  display: inline;
  word-wrap: break-word;
}
