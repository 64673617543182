.Wrapper {
  margin-top: 30px;

  @media (min-width: $smBreakpoint) {
    margin-top: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-top: 50px;
  }
}

.NoLead {
  margin-bottom: $margin16;
}

.HeadingOverviewWrapper {
  margin-bottom: 6px;
  position: relative;
  text-align: center;

  &::after {
    background-color: $black;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(50% - 2px);
    width: 100%;
    z-index: $zIndexBehindContent;
  }

  @media (min-width: $smBreakpoint) {
    margin-bottom: 17px;

    &::after {
      height: 2px;
      top: calc(50% - 4px);
    }
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 26px;
  }
}

.HeadingOverviewWrapperWithAlerts {
  @media (min-width: $smBreakpoint) {
    margin-bottom: 6px;
  }
}

.SubscribeButtonWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 18px;
  }
}

.HeadingOverview {
  composes: HeadingOverview from 'typography.legacy.css';
  background-color: $white;
  display: inline-block;
  margin: 0 auto;
  max-width: 96%;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 9px;
  padding-top: 5px;
  word-wrap: break-word;

  @media (min-width: $smBreakpoint) {
    padding-left: 30px;
    padding-right: 20px;
  }

  @media (min-width: $xlBreakpoint) {
    padding-left: 40px;
    padding-right: 25px;
  }
}

.Lead {
  composes: Lead1 from 'typography.legacy.css';
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}
