.TextWrapper {
  margin-bottom: 20px;
  max-width: 100%;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 35px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 45px;
  }

  &.GalleryDetailSpacing {
    @media (min-width: $xlBreakpoint) {
      position: relative;
      top: -5px;
    }
  }

  @media print {
    width: 100%;
  }
}

.TextWrapperVideoDetail {
  margin-bottom: 0;
  text-align: right;
}

.ParagraphWhite {
  @mixin Paragraph; /* we have to use the mixin here, otherwise the style would be overwritten by CaptionAuthorCopyRight. This is because of the declaration order in the typography.css file */
  composes: RichtextWrapper from 'TextParagraph/styles.legacy.css';
  color: $white;

  p {
    color: $white;

    strong, em {
      color: $white
    }
  }
}

.CaptionAuthorCopyRight {
  composes: CaptionAuthorCopyRight from 'typography.legacy.css';
}

.CaptionAuthorCopyRightGray {
  composes: CaptionAuthorCopyRightGray from 'typography.legacy.css';
}

.CaptionAuthorParagraph {
  composes: CaptionAuthorParagraph from 'typography.legacy.css';

  p {
    display: inline;

    b,
    strong {
      font-weight: bold;
    }

    i,
    em {
      font-style: italic;
    }

    :global(.catchquestion) {
      font-weight: 900;
      text-transform: uppercase;
    }

    a {
      @mixin InlineHover;
      border-bottom: 1px solid;
      color: $inherit;
    }

    u {
      text-decoration: underline;
    }
  }
}
