.TeaserWrapper {
  border: 1px solid;
  border-color: $redA;
  display: block;
  min-height: 170px;
  overflow-wrap: break-word;
  position: relative;
  word-break: break-word;

  @media (min-width: $smBreakpoint) {
    min-height: 260px;
  }

  @media (min-width: $xlBreakpoint) {
    max-width: 300px;
  }
}

.ShortTitle {
  composes: HeadingCatch2 from 'typography.legacy.css';
  color: $redA; margin: 20px 15px 5px;

  @media (min-width: $xlBreakpoint) {
    margin: $margin32 20px 5px 20px;
  }
}

.Title {
  composes: Heading6 from 'typography.legacy.css';
  color: $redA; margin: 0 0 50px 15px;
  max-width: calc(100% - 134px);

  @media (min-width: $smBreakpoint) {
    margin: 0 15px 142px;
    max-width: 100%;
  }

  @media (min-width: $xlBreakpoint) {
    margin: 0 20px 211px;
  }
}

.BannerWrapper {
  align-items: center;
  background: $gradientRed;
  bottom: 0;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  width: 100%;

  @media (min-width: $xlBreakpoint) {
    height: 65px;
  }
}

.Image {
  bottom: 36px;
  box-shadow: $shadowC;
  height: 80px;
  position: absolute;
  right: 37px;
  transform: rotate(-10deg);
  width: 59px;

  @media (min-width: $smBreakpoint) {
    bottom: 32px;
    height: 100px;
    right: 39px;
    width: 74px;
  }

  @media (min-width: $xlBreakpoint) {
    bottom: 44px;
    height: 160px;
    right: 53px;
    width: 118px;
  }
}

.ArrowButtonStyle {
  margin-right: 26px;
  min-width: 116px;

  @media (min-width: $smBreakpoint) {
    margin-right: 20px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-right: 21px;
    min-width: 140px;
  }
}

.WrapperStyle {
  border-color: $redA;
  text-align: left;

  .Image {
    left: auto;
    right: 53px;
  }

  .ArrowButtonStyle {
    margin: 0;
    margin-right: 21px;
    min-width: 140px;
  }

  .Title {
    @mixin Heading6;
  }

  .ShortTitle {
    @mixin HeadingCatch2;
  }

  .Title,
  .ShortTitle {
    color: $redA;
  }

  .BannerWrapper {
    background: $gradientRed;
  }
}
