.Wrapper {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 100%;
}

.Wrapper:hover {
  .Title span {
    border-bottom-color: $redA;
  }
}

.ImageWrapper {
  float: left;
  height: auto;
  margin-bottom: 0;
  margin-right: 10px;
  max-width: 80px;
  position: relative;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    max-width: 115px;
  }
}

.Image {
  display: block;
  height: auto;
  width: 80px;

  @media (min-width: $smBreakpoint) {
    width: 115px;
  }
}

.ContentWrapper {
  float: left;
  position: relative;
  width: calc(100% - 90px);
  word-wrap: break-word;

  @media (min-width: $smBreakpoint) {
    width: calc(100% - 125px);
  }
}

.Title {
  composes: Heading3B from 'typography.legacy.css';

  span {
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.3s;
  }
}

.ShortTitle {
  composes: HeadingCatch4 from 'typography.legacy.css';
  margin-bottom: 5px;
}

.Badge {
  position: absolute;
  right: 0;
  top: -10px;
  white-space: nowrap;
}

.BadgePositionBottom {
  bottom: -8px;
  right: 0;
  top: auto;

  @media (min-width: $smBreakpoint) {
    bottom: -10px;
  }
}

.IconStyle {
  composes: IconStyle from 'App/components/Teaser/styles.legacy.css';
  bottom: 0;

  &::before {
    bottom: 5px;
    left: 5px;

    @media (min-width: $smBreakpoint) {
      bottom: 10px;
      left: 10px;
    }
  }

  @media (min-width: $smBreakpoint) {
    max-width: 53px;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 1.2rem;
  }
}

.IconMovieOutlineStyle {
  composes: IconMovieOutlineStyle from 'App/components/Teaser/styles.legacy.css';
}

.SkeletonWrapper {
  composes: SkeletonWrapper from 'App/components/Teaser/components/TeaserS/styles.legacy.css';
}

.SkeletonContentWrapper {
  height: 40px;
  position: relative;
}

.SkeletonShortTitle,
.SkeletonTitle {
  background: $grayPlaceholder;
  border-radius: 3px;
  height: 13px;
  left: 0;
  position: absolute;
}

.SkeletonShortTitle {
  top: 0;
  width: 80px;
}

.SkeletonTitle {
  top: 20px;
  width: 120px;
}
