.Wrapper {
  margin-bottom: $margin16;
  margin-top: 36px;
}

.Title {
  composes: Heading6 from 'typography.legacy.css';
  margin-bottom: $margin8;
}

.Description {
  composes: FormLabelBig from 'typography.legacy.css'; 
  margin-bottom: 36px;
}
