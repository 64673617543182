.Title {
  composes: Heading7 from 'typography.legacy.css';
  margin-bottom: $margin24; 
  margin-top: $margin24;
  overflow-wrap: break-word;
  word-break: break-word;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin16;
  }

  &.MarketingPage {
    @mixin Heading2A;
  }
}

.AccordionInner {
  padding-bottom: 25px;
  padding-top: 1px;
}

.Paragraphs {
  composes: Paragraph2 from 'typography.legacy.css';
}
