.Wrapper {
  display: inline-block;
  line-height: 0;
  position: relative;
  width: 100%;

  &:hover {
    .Title span {
      border-bottom-color: $redA;
    }
  }
}

.ImageWrapper {
  height: auto;
  position: relative;
  width: 100%;
}

.Image {
  display: block;
  height: auto;
  margin-bottom: 12px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin16;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 22px;
  }
}

.ContentWrapper {
  word-wrap: break-word;
}

.Title {  
  composes: Heading2B from 'typography.legacy.css';

  span {
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.3s;
  }
}

.ShortTitle {
  composes: HeadingCatch2 from 'typography.legacy.css';
  margin-bottom: 3px;
}

.Badge {
  composes: Badge from 'App/components/Teaser/styles.legacy.css';
}

.BadgePositionBottom {
  bottom: -7px;
  top: auto;

  @media (min-width: $smBreakpoint) {
    bottom: -10px;
  }
}

.ArrowButtonAddClass {
  @media (min-width: $smBreakpoint) {
    /* has to be in px because its used inside the ArrowButton component */
    font-size: 14px;
  }
}

.IconStyle {
  composes: IconStyle from 'App/components/Teaser/styles.legacy.css';

  &::before {
    @media (min-width: $smBreakpoint) {
      bottom: 10px;
      left: 10px;
    }

    @media (min-width: $xlBreakpoint) {
      bottom: 15px;
      left: 15px;
    }
  }

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2.5rem;
  }
}

.IconMovieOutlineStyle {
  composes: IconMovieOutlineStyle from 'App/components/Teaser/styles.legacy.css';
}
